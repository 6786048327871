import React, { useState } from 'react';
import Header from '../../components/header/header';
import { TranslationService } from '../../services/translation.service';
import * as styles from './profile.module.scss';
import { TranslationProvider } from '../../contexts/translation.context';
import Footer from '@/src/components/footer/footer';
import Navbar from '@/src/components/navbar/navbar';
import { navigate, PageProps } from 'gatsby';
import { Badge } from '@/src/components/badge/badge';
import { BiKey, BiLogOut } from 'react-icons/bi';
import { AuthService } from '@/src/services/auth.service';
import { useEffect } from 'react';
import { getUserService } from '@/src/services/user.service';
import { UserModel } from '@/src/models/user.model';
import useAuthStatus from '@/src/hooks/auth-status.hook';
import { SignupFunnelCard } from '@/src/components/signup-funnel-card/signup-funnel-card';
import { Helmet } from 'react-helmet';

export default function ProfilePage({ pageContext }: PageProps) {
  const [translation] = useState(new TranslationService(pageContext));
  const [user, setUser] = useState(null as UserModel);
  const { isAuthenticated } = useAuthStatus();

  useEffect(() => {
    const userService = getUserService();
    userService.init();
    const subscription = userService.user$.subscribe(user => setUser(user));
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const logout = async () => {
    await new AuthService().logout();
    navigate(`/${translation.lang}/login`);
  };

  return (
    <TranslationProvider value={translation}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{translation.translate('helmet.profile_title')}</title>
        <meta name="description" content={translation.translate('helmet.profile_description')} />
        <meta http-equiv="content-language" content={translation.lang} />
      </Helmet>
      <Header></Header>

      <div className="scrollable">
        <div className={styles.wrapper}>
          <div className={styles.profileWrapper + ' app__wrapper'}>

            {!isAuthenticated ?
              <div className={styles.funnelContainer}>
                <SignupFunnelCard actionKey="profile"></SignupFunnelCard>
              </div>
              : null}

            {isAuthenticated ?
              <div className={styles.profileInner}>
                <h1 className={styles.heading}>
                  {translation.translate('profile.heading')}
                </h1>
                {user ?
                  <dl>
                    <dt>{translation.translate('profile.first_name')}:</dt>
                    <dd>{user.firstName}</dd>
                    <dt>{translation.translate('profile.last_name')}:</dt>
                    <dd>{user.lastName}</dd>
                    <dt>{translation.translate('profile.address.street')}:</dt>
                    <dd>{user.address.street}</dd>
                    <dt>{translation.translate('profile.address.zip')}:</dt>
                    <dd>{user.address.zip}</dd>
                    <dt>{translation.translate('profile.address.city')}:</dt>
                    <dd>{user.address.city}</dd>
                    <dt>{translation.translate('profile.country')}:</dt>
                    <dd>{user.country}</dd>
                    <dt>{translation.translate('profile.birth_year')}:</dt>
                    <dd>{user.birthYear}</dd>
                    <dt>{translation.translate('profile.email')}:</dt>
                    <dd>{user.email}</dd>
                  </dl>
                  : null}
                <div className={styles.profileActions}>
                  <Badge.Filled text={translation.translate('profile.change_password')} icon={<BiKey />}></Badge.Filled>
                  <Badge.Filled onClick={logout} text={translation.translate('profile.log_out')} icon={<BiLogOut />}></Badge.Filled>
                </div>

              </div>
              : null}

          </div>
        </div>
        <Footer></Footer>
      </div>
      <Navbar></Navbar>
    </TranslationProvider>
  );
}

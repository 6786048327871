import { useTranslation } from '@/src/contexts/translation.context';
import { Link } from 'gatsby';
import React from 'react';
import { LargeButton } from '../button/button';
import * as styles from './signup-funnel-card.module.scss';

type SignupFunnelCardProps = {
  actionKey: string;
};

export function SignupFunnelCard({ actionKey }: SignupFunnelCardProps) {
  const translation = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.headingRow}>
        <h1>{translation.translate(`signup_funnel.${actionKey}.header`)}</h1>
      </div>

      <div className={styles.actionTakenRow}>
        <p>{translation.translate(`signup_funnel.${actionKey}.body`)}</p>
      </div>

      <div className={styles.ctaRow}>
        <Link to={`/${translation.lang}/signup`}>
          <LargeButton type="button">
            <span>{translation.translate('signup_funnel.create_account')}</span>
          </LargeButton>
        </Link>

        <div className={styles.divider}>
          <div></div>
          <span>{translation.translate('signup_funnel.or')}</span>
          <div></div>
        </div>

        <p className={styles.login}>
          {translation.translate('signup_funnel.already_signed_up')}
          <Link to={`/${translation.lang}/login`}>{translation.translate('signup_funnel.log_in')}</Link>
        </p>
      </div>
    </div>
  );
}